import { useQuery } from "react-query"
import { ProductCatalogType } from "../../contracts/"
import { fetchRecommendations } from "../api/accountAPI"
import { fetchProductsList } from "../api/productsAPI"

type UseRecommendationsType = (props?: {
  limit?: number
  isAuth?: boolean
}) => {
  isLoading: boolean
  products: ProductCatalogType[] | null
}

export const useRecommendations: UseRecommendationsType = ({
  limit,
  isAuth = false,
} = {}) => {
  const { data: dataRecommendationsKeys } = useQuery(
    ["recommendationsKey", isAuth],
    () => fetchRecommendations(),
    {
      enabled: isAuth,
    },
  )
  const { isLoading, data: dataProducts } = useQuery(
    ["recommendationsProduct", dataRecommendationsKeys, isAuth],
    () =>
      fetchProductsList({
        uuids: (dataRecommendationsKeys || [])
          .filter((_, i) => (limit !== undefined ? i < limit : true))
          .join(","),
      }),
    {
      enabled:
        !!dataRecommendationsKeys &&
        dataRecommendationsKeys.length > 0 &&
        isAuth,
    },
  )

  return {
    isLoading: isLoading,
    products: dataProducts || null,
  }
}
